import React, { useRef, useState, useEffect } from 'react';
import './style.scss'
import Icons from '../Icons';
import NoResult from "../../assets/img/no-result.png"
import userImage from '../../assets/img/user.png'
import OffersHubTable from '../OffersHubTable';
import { getApiCall, getApiCallNew, putApiCallNew } from '../../service/index'
import { useLocation, useNavigate } from 'react-router-dom';
import Autosuggest from 'react-autosuggest';
import Dropdown from '../Dropdown';
import Sidebar from '../Sidebar';
import LoaderNew from '../LoaderNew';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PopupSec from '../PopupSec';

const UserManagementTable = (props) => {
  const { userSearchList, country, setCountry, roles, setRoles, perList, setPerlist, staList, setStaList, useperList, setUseperList, setIpopup, setPopupMessage, setStatus, setStaDropVal, staDropVal, perDropVal, setPerDropVal, setUserPermisson, offset, total, limit, setOffset, setSort, setSortOrder, sort, sortOrder, userSearch, setUserSearch, searched, deleteSearch,
    suggestions, onSuggestionsClearRequested, getSuggestionValue, renderSuggestion, inputProps, onSuggestionSelected, value
   } = props;
  const [activeRow, setActiveRow] = useState(null);
  const [userName, setUserName] = useState(null);
  const wrapperRef = useRef(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [userDetail, setUserDetail] = useState(null)
  const [loading, setLoading] = useState(false)

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
    if (!isSidebarOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  };
  let tableHeader = [
    { label: 'Name', value: 'userName' },
    { label: 'Email ID', value: 'email' },
    { label: 'User Designation', value: 'designation' },
    { label: 'User Permission', value: 'userPermission' },
    { label: 'Country', value: 'country' },
    { label: 'Last Active', value: 'lastActive' },
    { label: 'Status', value: 'status' },
    { label: '', value:''}
  ];
  const perRef = useRef(null);
  const [perDrop, setPerDrop] = useState(false)
  

  const navigate = useNavigate();

  const perDropdown = () => {
    setPerDrop(!perDrop)
  }
  const perdropFunc = (val) => {
    setPerDropVal(val)
    setUserPermisson([val])
    setOffset(0)
  }
  const staRef = useRef(null);
  const [staDrop, setStaDrop] = useState(false)
  

  const staDropdown = () => {
    setStaDrop(!staDrop)
  }
  const stadropFunc = (val) => {
    setStaDropVal(val)
    setStatus([val])
    setOffset(0)
  }
  const dotRef = useRef(null);
  const dotList = ["View Details"]
  const [dotDrop, setDotDrop] = useState(false)
  const [dotDropVal, setDotDropVal] = useState("")

  const dotDropdown = (email) => {
    setDotDrop(!dotDrop)
    setDotDropVal(email)
    // toggleSidebar()
  }
  const dotDropFunc = (val) => {
    setDotDrop(false)
    toggleSidebar()
  }
  const useperRef = useRef(null);
  const [useperDrop, setUseperDrop] = useState(false)
  const [useperDropVal, setUseperDropVal] = useState("")

  const useperDropdown = (detail) => {
    setUseperDrop(!useperDrop)
    setUseperDropVal(detail?.email)
    setUserDetail(detail)
    console.log(detail)
    // toggleSidebar()
  }
  const useperDropFunc = (val) => {
    let params = {
      "userId": userDetail?.id,
      "country": userDetail?.country,
      "designation": userDetail?.designation,
      "roleName": val,
      "userStatus": userDetail?.status
    }
    editUser(params);
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setActiveRow(null)
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  useEffect(()=> {
    const handleClickOutside = (event) => {
        if (perRef.current && !perRef.current.contains(event.target)) {
            setPerDrop(false); // Close the dropdown
        }
        if (staRef.current && !staRef.current.contains(event.target)) {
            setStaDrop(false); // Close the dropdown
        }
        // if (dotRef.current && !dotRef.current.contains(event.target)) {
        //     setDotDrop(false); // Close the dropdown
        // }
        // if (useperRef.current && !useperRef.current.contains(event.target)) {
        //     setUseperDrop(false); // Close the dropdown
        // }
    };
    document.addEventListener('mousedown', handleClickOutside);
  
      return () => {
          document.removeEventListener('mousedown', handleClickOutside);
      };
  },[])

  

  const onClickEdit = (id) => {
    navigate('/merchants/add-merchant',{state: { id, searched }})
  }

  const editUser = (detail) => {
    setLoading(true)
    putApiCallNew('/users', detail
      
    ).then(async (res) => {
      if(res.status===200 || res.status===201) {
        const response = await res.json();
        setPopupMessage(response?.messages)
        userSearchList();
        setLoading(false)
        setIpopup(true)
        return true
        
      } 
      else if (res.status===500){
        setLoading(false)
        const response = await res.json();
        if(response?.error?.message){
        toast.error(response?.error?.message)
        }
        else{
        toast.error("Internal Server Error")}
      }
      else if (res?.status===401){
        setLoading(false)
        localStorage.removeItem("token")
        navigate('/login')
        setTimeout(() => {
          toast.error("Session has expired. Please login again.")
        }, 1000);
      }
      else {
        const response = await res.json();
        toast.error(JSON.stringify(response?.error))
        // setShowPopup(true)
        setLoading(false)
      }
      return false
    }
    ).catch(e => {
      setLoading(false)
      toast.error('Something went wrong, please validate all the fields and try again!')
      console.log(e)
    })
  }


  return (
    <>
    {
      loading && <LoaderNew/>
    }
    <Sidebar editUser={editUser} toggleSidebar={toggleSidebar} isOpen={isSidebarOpen} userDetail={userDetail} countries={country} roles={roles}/>
    {isSidebarOpen && <>
      <div className="backdrop" onClick={toggleSidebar}></div></>}
      <div className='user-filter-div' style={{ marginTop: 30 }} >
      <div className='user-filter-section'>
      {/* <div className='search-section'>
      <div className={`${userSearch!=='' && "bordBlue"} search-container`} style={{position:"relative"}}>
            <div className='search-icon'>
            <Icons
              name="search"
              width="24"
              height="24"
              fill="#111827"
            />
            </div>
            <Autosuggest
              suggestions={suggestions}
              onSuggestionsFetchRequested={() => {}}
              onSuggestionsClearRequested={onSuggestionsClearRequested}
              getSuggestionValue={getSuggestionValue}
              renderSuggestion={renderSuggestion}
              inputProps={inputProps}
              onSuggestionSelected={onSuggestionSelected}
            />
            {value !=="" &&
            <div className='cancel-icon' onClick={()=>deleteSearch()}>
            <Icons
              name="xcancel"
              width="24"
              height="24"
              fill="#1F2937"
            />
            </div>}
            </div>
        </div> */}
        <div className='permission' ref={perRef} onClick={perDropdown}>
          User Permission:<span style={{marginLeft:"4px"}}>{perDropVal}</span>
          <span className={`droparr ${perDrop && "arr-rot"}`}>
            <Icons     
              name= "nav-arrow-down"
              width= "20"
              height= "20"
              fill= "#374151"
            />
          </span>
          {perDrop && <Dropdown dropList={perList} dropFunc={perdropFunc} dropVal={perDropVal} dropClass={"drop2"}/>}
          </div>
          <div className='status' ref={staRef} onClick={staDropdown}>
          Status:<span style={{marginLeft:"4px"}}>{staDropVal}</span>
          <span className={`droparr ${staDrop && "arr-rot"}`}>
            <Icons     
              name= "nav-arrow-down"
              width= "20"
              height= "20"
              fill= "#374151"
            />
          </span>
          {staDrop && <Dropdown dropList={staList} dropFunc={stadropFunc} dropVal={staDropVal} dropClass={"drop3"}/>}
          </div>
        </div>
        {/* {userSearch? <div className='search-heading'>
          <span onClick={deleteSearch} style={{paddingTop:"3px"}}>
          <Icons
              name="nav-arrow-left"
              width="32"
              height="32"
              fill="rgba(0, 0, 0, 1)"
          />
          </span>
          Showing Results for <span className='search-name'>"{searched?.label}"</span>
        </div>:<div></div>} */}
        <div className='pagination'>
          {props.data?.length===0? 0:offset + 1} - {offset + limit > total ? total : offset + limit} of {total}
          <span onClick={() => { if (offset > 0) setOffset(offset - limit) }} >
            <Icons
              name="left-arrow"
              width="7"
              height="13"
              fill={offset === 0 ? "rgba(0, 0, 0, 0.3)" : "rgba(0, 0, 0, 1)"}
            /></span>
          <span onClick={() => { if (offset + limit < total) setOffset(offset + limit) }} >
            <Icons
              name="right-arrow"
              width="7"
              height="13"
              fill={offset + limit >= total ? "rgba(0, 0, 0, 0.3)" : "rgba(0, 0, 0, 1)"}
            />
          </span>
        </div>
      </div>
      <div className='user-management-table'>
        <table>
          <thead>
            {tableHeader && tableHeader.map(
              ({ label, value }) => {
                return (<td>
                  <div
                    style={{cursor:value===""&&"default"}}
                    onClick={
                      () => {
                        if(value!=="")
                          {
                        if (sortOrder === "asc" && sort === value) {
                          setSortOrder("desc")
                        }
                        else if (sortOrder === "desc" && sort === value) {
                          setSortOrder("")
                          setSort("")
                        }
                        else {
                          setSortOrder("asc")
                          setSort(value)
                        }
                        }
                      }
                    }
                  >
                    {label}
                    {
                      value !== '' &&
                      <span className='sort' >
                        <div><Icons name="arrow-up" width={6} height={8} fill={props?.sort === value && props.sortOrder === "asc" ? "#1A5DEE" : "#9CA3AF"} /></div>
                        <div><Icons name="arrow-down" width={6} height={8} fill={props?.sort === value && props.sortOrder === "desc" ? "#1A5DEE" : "#9CA3AF"} /></div>
                      </span>
                    }
                  </div>
                </td>)
              }
            )}
          </thead>
          {props.data?.length===0 ? 
            <div className='no-result'>
              <div className='no-img'><img src={NoResult} alt="no-result" height={180} width={155}/></div>
              <div className='nor-text'>Sorry, no results found :(</div>
              <div className='nor-text'>Try Searching again</div>
            </div>
            :
          <tbody>
            {props.data?.map((userDetails) => {
              return (<tr>
                <td>
                    <div className='user-div'>
                        <div><img src={userImage} width={32} height={32} alt="user"/></div>
                        <div>{userDetails.userName}</div>
                    </div>
                </td>
                <td>{userDetails.email}</td>
                {/* <td>{userDetails.merchantDescription?.length > 40 ? userDetails.merchantDescription?.substring(0, 40) + '...' : userDetails.merchantDescription}</td> */}
                <td>{userDetails.designation}</td>
                <td>
                <div className='userper' ref={useperRef} onClick={()=>useperDropdown(userDetails)}>
                <span>{userDetails.userPermission}</span>
                <span className={`droparr ${(useperDrop && useperDropVal===userDetails.email) && "arr-rot"}`} >
                    <Icons     
                    name= "nav-arrow-down"
                    width= "20"
                    height= "20"
                    fill= "#374151"
                    />
                </span>
                <Dropdown drop={useperDrop} dropC={useperDropVal} userDetails={userDetails} dropList={useperList} dropFunc={useperDropFunc} dropVal={userDetails.userPermission} dropClass={"drop4"}/>
                </div></td>
                <td>{userDetails.country}</td>
                <td>{userDetails.lastActive}</td>
                {/* <td>{userDetails.lastActive?.length > 0 ? new Date(userDetails.lastActive).toISOString().split('T')[0] : userDetails.lastActive}</td> */}
                <td><div className={`status ${userDetails.status==="Active"? "active":"inactive"}`}>{userDetails.status==="Active"? "Active":"Inactive"}</div></td>
                <td>
                <div className='dot' ref={dotRef} onClick={()=>{
                  setUserDetail(userDetails)
                  dotDropdown(userDetails.email)
                  }}>
                <span className='' >
                    <Icons     
                    name= "table-more"
                    width= "24"
                    height= "24"
                    fill= "#374151"
                    />
                </span>
                {(dotDrop && dotDropVal===userDetails.email) && <Dropdown dropList={dotList} dropFunc={dotDropFunc} dropClass={"drop5"}/>}
                </div>
                </td>
                {/* <td className='action-div'>
                  <div className='action' onClick={()=>{onClickEdit(userDetails.merchantId)}} >Edit</div>
                  <div className='seperator'>|</div>
                  <div className='action' style={{cursor:userDetails.offerCount===0 &&"default",color:userDetails.offerCount===0 &&"#ccc"}} >View Offers</div> </td> */}
              </tr>)
            })}
          </tbody>
          }
        </table>
      </div>
      <ToastContainer
      // className="toaster-top toast-error-container"
      autoClose={6000}
      position='top-right'
    />
    </>
  )
}

export default UserManagementTable