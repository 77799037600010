import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import './style.scss'
import hand from "../../assets/img/hand.png"
import LoaderNew from '../../components/LoaderNew';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getApiCallNew, postApiCall, postApiCallNew, postTypeAhead } from '../../service/index'
import Autosuggest from 'react-autosuggest';
import UserManagementTable from '../../components/UserManagementTable'
import PopupSec from '../../components/PopupSec';
const Icons = React.lazy(() => import('../../components/Icons'));

const UserManagement = () => {
  const navigate = useNavigate()
  const location = useLocation(); 
  const searchedName = location.state?.searched;
  const [usersDetails, setUsersDetails] = useState([]);
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(0);
  const [sort, setSort] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const [loading, setLoading] = useState(false);
  const [userSearch, setUserSearch] = useState('')
  const [searched, setSearched] = useState(null)
  const [suggestionSelected, setSuggestionSelected] = useState(false);
  const [value, setValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const limit = 10;
  const [popupMessage, setPopupMessage] = useState([])
  const [ipopup, setIpopup] = useState(false)
  const [country, setCountry] = useState(null)
  const [roles, setRoles] = useState(null)
  const [status, setStatus] = useState([])
  const [staList, setStaList] = useState(null)
  const [staDropVal, setStaDropVal] = useState("All")
  const [useperList, setUseperList] = useState(null)
  const [userPermission, setUserPermisson] = useState([])
  const [perList, setPerList] = useState(null)
  const [perDropVal, setPerDropVal] = useState("All")

  useEffect(()=> {
    if (searchedName) {
      setUserSearch(searchedName)
      setValue(searchedName.label)
    }
    // else {
    //   userSearchList();
    // }
  },[])

  useEffect(() => {
    if ((offset===0 || offset) || sort !=='' || sortOrder !=='' || userSearch !== '' || status.length !==0 || userPermission.length !==0) {
      userSearchList();
    }
  }, [offset, sort, sortOrder, userSearch, status, userPermission])

  const userSearchList = (val) => {
    try {
      const url = '/users';
      const data = {
        "offset": offset,
        "limit": limit,
        "filters": {
          "status": status,
          "userPermission": userPermission
        },
        "sortBy": sort,
        "sortOrder": sortOrder,
        "searchWord": (val&& val==="del")? "":userSearch !==''? userSearch?.label:""
      }
      setLoading(true)
      postApiCallNew(url, data).then(async (res) => {
        if (res.status===200) {
          const response = await res.json();
          if (response?.usersListResponses && response?.totalCount) {
            setLoading(false)
            setSearched(userSearch)
            setUsersDetails(response?.usersListResponses)
            setTotal(response?.totalCount)
          }
          else {
            setLoading(false)
            setSearched(userSearch)
            setUsersDetails(response?.usersListResponses)
            setTotal(response?.totalCount)
          }
        } 
        else if (res?.status===404){
          setLoading(false)
          setSearched(userSearch)
          setUsersDetails([{"result":"no"}])
          setTotal(0)
        }
        else if (res?.status===500){
          setLoading(false)
          toast.error("Internal Server Error")
          
        }
        else if (res?.status===401){
          setLoading(false)
          localStorage.removeItem("token")
          navigate('/login')
          setTimeout(() => {
            toast.error("Session has expired. Please login again.")
          }, 1000);
        }
        else {
          const response = await res.json();
          setLoading(false)
          toast.error(JSON.stringify(response?.error))
          toast.error(JSON.stringify(response?.error?.message))
          toast.error(JSON.stringify(response?.detail))
        }
        
      }
      )

    }
    catch (err) {
      setLoading(false)
      console.log(err)
    }
  }

  const handleMerchantName = (value) => {
    console.log(value)
    setUserSearch(value)
  }

  const handleInputChange = (value) => {
    console.log(value)
    // if (value) {
      // setMerchantSearch(value)
    // }
  }

  const addMerchant = () => {
    navigate('/merchants/add-merchant')
  }
  const handleKeyDownOpArr = (event) => {
    if (!userSearch) return;
    switch (event.key) {
      case 'Enter':
        console.log(event)
        setUserSearch(event.target.value)
        event.preventDefault();
    }
  };
  const handleCreateOption = inputValue => {
    // If the user presses Enter without selecting from the dropdown,
    // create a new merchant with the entered value
    return { value: inputValue, label: inputValue };
  };


  useEffect(() => {
    // Define a function to fetch suggestions from your API
    const fetchSuggestions = async (inputValue) => {
      try {
        const response = await postTypeAhead(`/users/search`,{searchWord:inputValue});
        setSuggestions(response.searchResults.map((item) => ({ value: item, label: item }))); // Assuming the API returns an array of suggestions
        
      } catch (error) {
        console.error('Error fetching suggestions:', error);
      }
    };

    // Call the fetchSuggestions function when value changes
    if (value.trim() !== '' && value.trim().length>2) {
      fetchSuggestions(value.trim());
    } else {
      setSuggestions([]); // Clear suggestions if input is empty
    }
  }, [value]);



const getSuggestionValue = suggestion => suggestion.label;

const renderSuggestion = suggestion => {
  const regex = new RegExp(`(${value})`, 'gi');
  const parts = suggestion.label.split(regex);
  
  return (
    <div>
      {parts.map((part, index) => 
        part.toLowerCase() === value.toLowerCase() ? (
          <span key={index} style={{ color: '#526EFF' }}>{part}</span>
        ) : (
          part
        )
      )}
    </div>
  );
};

const onChange = (event, { newValue }) => {
  setValue(newValue);
};



const onSuggestionSelected = (event, { suggestion }) => {
  setUserSearch(suggestion); // Set the selected item when a suggestion is clicked
};

const onSuggestionsClearRequested = () => {
  setSuggestions([]);
  // setOffset(0)
  // setStatus([])
  // setUserPermisson([])
  // setSort('')
  // setSortOrder('')
  // setStaDropVal("All")
  // setPerDropVal("All")
};

const onKeyPress = event => {
  if (event.key === 'Enter') {
    event.preventDefault();
    // Call your API with the input value
    setUserSearch({label:value,value:value})
    setOffset(0)
    setStatus([])
    setUserPermisson([])
    setSort('')
    setSortOrder('')
    setStaDropVal("All")
    setPerDropVal("All")
  }
};

const deleteSearch = () => {
  setUserSearch('')
  setValue('')
  setSearched(null)
  // userSearchList("del");
  setOffset(0)
  setStatus([])
  setUserPermisson([])
  setSort('')
  setSortOrder('')
  setStaDropVal("All")
  setPerDropVal("All")
}

const inputProps = {
  placeholder: 'Search name, email id',
  value,
  onChange,
  onKeyPress
};
useEffect(() => {
  try {
    getApiCallNew(`/list/countries`).then(async (res) => {
      console.log(res)
      
      if (res?.status ===200) {
        if (res?.data?.countries) {
          let dvalue=res?.data?.countries?.slice(1,).map((item) => ({ value: item, label: item }));
          setCountry(dvalue)
        }
      }
      else if (res?.status===500){
        // setLoading(false)
        // toast.error("Internal Server Error")
      }
      else if (res?.status===401){
        // setLoading(false)
        localStorage.removeItem("token")
        navigate('/login')
        // setTimeout(() => {
        //   toast.error("Session has expired. Please login again.")
        // }, 1000);
      }
      else {
        // toast.error(res?.error)
        // setLoading(false)
      }
    }).catch(e=>{
      console.log(e)
    });

  } catch(e) {
    console.log(e)
  }
  try {
    getApiCallNew(`/list/roles`).then(async (res) => {
      console.log(res)
      
      if (res?.status ===200) {
        if (res?.data) {
          let newArray = res?.data.map(obj => {
            return obj?.roleName
          });
          setRoles(newArray)
          setUseperList(newArray)
        }
      }
      else if (res?.status===500){
        // setLoading(false)
        // toast.error("Internal Server Error")
      }
      else if (res?.status===401){
        // setLoading(false)
        localStorage.removeItem("token")
        navigate('/login')
        // setTimeout(() => {
        //   toast.error("Session has expired. Please login again.")
        // }, 1000);
      }
      else {
        // toast.error(res?.error)
        // setLoading(false)
      }
    }).catch(e=>{
      console.log(e)
    });

  } catch(e) {
    console.log(e)
  }
  try {
    getApiCallNew(`/users/filters`).then(async (res) => {
      console.log(res)
      
      if (res?.status ===200) {
        if (res?.data) {
          setStaList(res?.data?.status)
          setPerList(res?.data?.userPermission)

        }
      }
      else if (res?.status===500){
        // setLoading(false)
        // toast.error("Internal Server Error")
      }
      else if (res?.status===401){
        // setLoading(false)
        localStorage.removeItem("token")
        navigate('/login')
        // setTimeout(() => {
        //   toast.error("Session has expired. Please login again.")
        // }, 1000);
      }
      else {
        // toast.error(res?.error)
        // setLoading(false)
      }
    }).catch(e=>{
      console.log(e)
    });

  } catch(e) {
    console.log(e)
  }
},[])

  return (
    <>
    <PopupSec popupMessage={popupMessage} ipopup={ipopup} setIpopup={setIpopup}/>
    <div className='users-hub'>
      { 
      loading ? <LoaderNew /> :
      usersDetails &&
        <div className='offer-table-div'>
          <div className='offer-main-header'>
            <div className='offer-table-head-sec'>
              <div className='offer-table-title'>
                User Management
              </div>
            </div>
            <div className='search-section'>
      <div className={`${userSearch!=='' && "bordBlue"} search-container`} style={{position:"relative"}}>
            <div className='search-icon'>
            <Icons
              name="search"
              width="24"
              height="24"
              fill="#111827"
            />
            </div>
            <Autosuggest
              suggestions={suggestions}
              onSuggestionsFetchRequested={() => {}}
              onSuggestionsClearRequested={onSuggestionsClearRequested}
              getSuggestionValue={getSuggestionValue}
              renderSuggestion={renderSuggestion}
              inputProps={inputProps}
              onSuggestionSelected={onSuggestionSelected}
            />
            {value !=="" &&
            <div className='cancel-icon' onClick={()=>deleteSearch()}>
            <Icons
              name="xcancel"
              width="24"
              height="24"
              fill="#1F2937"
            />
            </div>}
            </div>
        </div>
          </div>
          <div>
            <UserManagementTable 
            userSearchList={userSearchList}
            setIpopup={setIpopup}
            setPopupMessage={setPopupMessage}
            country={country}
            roles={roles}
            setRoles={setRoles}
            setCountry={setCountry}
            setStatus={setStatus}
            useperList={useperList}
            setUseperList={setUseperList}
            perList={perList}
            setPerList={setPerList}
            staList={staList}
            setStaList={setStaList}
            staDropVal={staDropVal}
            setStaDropVal={setStaDropVal}
            setUserPermisson={setUserPermisson}
            perDropVal={perDropVal}
            setPerDropVal={setPerDropVal}
            deleteSearch={deleteSearch} 
            searched={searched} 
            userSearch={userSearch} 
            setUserSearch={setUserSearch} 
            data={usersDetails} 
            offset={offset} 
            setOffset={setOffset} 
            sort={sort} 
            sortOrder={sortOrder} 
            total={total} 
            limit={limit} 
            setSort={setSort} 
            setSortOrder={setSortOrder}
            suggestions={suggestions}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            inputProps={inputProps}
            onSuggestionSelected={onSuggestionSelected}
            value={value} />
          </div>
        </div>
      }
    </div>
    <ToastContainer
      // className="toaster-top toast-error-container"
      autoClose={6000}
      position='top-right'
    />
    </>
  )
}

export default UserManagement