import { useReducer } from 'react';
import { createContext } from 'react';

export const AppContext = createContext("");

const appReducer = (state,action) =>{
    switch (action.type){
        case 'GET_SELECTED_HEADER':
            return state.selectedHeader
        case 'SET_SELECTED_HEADER':
            return {...state,selectedHeader:action.payload}
        case 'GET_SELECTED_SEC_HEADER':
            return state.selectedSecHeader
        case 'SET_SELECTED_SEC_HEADER':
            return {...state,selectedSecHeader:action.payload}
        case 'GET_UPLOADTAB':
            return state.uploadTab
        case 'SET_UPLOADTAB':
            return {...state,uploadTab:action.payload}
        case 'GET_ROLE':
            return state.role
        case 'SET_ROLE':
            return {...state,role:action.payload}
        case 'GET_MERCHANT_FILTERS':
            return state.merchantFilters
        case 'SET_MERCHANT_FILTERS':
            return { ...state, merchantFilters: action.payload }
        case 'GET_OFFER_FILTERS':
            return state.offerFilters
        case 'SET_OFFER_FILTERS':
            return { ...state, offerFilters: action.payload }
        default:
            return state
    }
}

export const AppContextProvider = ({children}) =>{

    const [state,dispatch] = useReducer(appReducer,{
        selectedHeader:"Offers",
        selectedSecHeader: "Manage Offers",
        uploadTab: "Single Upload",
        role: "",
        merchantFilters: {
            category: [],
            offerCount: [],
            channel: [],
            country: [],
            lastUpdatedDate: [],
        },
        offerFilters: {
            offerType: [],
            status: [],
            startDate: [],
            endDate: [],
            offerChannel: [],
            offerSource: [],
        },
    })

    return(
        <AppContext.Provider value={{state,dispatch}}>
            {children}
        </AppContext.Provider>
    )
}