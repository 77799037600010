import { useState,useRef, useEffect } from "react"
import "./style.scss"
import { toast } from "react-toastify";
import Icons from "../Icons";

const RejectModal = ({setRejectModalOpen,setShowWarning,bulkSubmit}) =>{


    const [currentTab,setCurrentTab] = useState('Bulk')
    const selectedOfferIds = Object.values(JSON.parse(localStorage.getItem("wonderList"))).flat().map(item=>item.id)
    const [currentIdTab,setCurrentIdTab] = useState(selectedOfferIds[0])
    const innerDivRef = useRef(null);
    const[count,setCount] = useState(0)
    const[submitVis,setSubmitVisible] = useState(false)
    const idListRef = useRef(null);
    const [canScrollLeft, setCanScrollLeft] = useState(false);
    const [canScrollRight, setCanScrollRight] = useState(true);
    const [Reasons,setReasons] = useState('')
    const selectedOffers = Object.values(JSON.parse(localStorage.getItem("wonderList"))).flat().map(item=>item.id)
    const [ReasonList,setReasonlist] = useState(selectedOffers.reduce((acc, item) => {
        acc[item] = '';
        return acc;
      }, {}))
    let reasons = Object.values(ReasonList)


    useEffect(()=>{
        let cnt = 0
        for(let i=0;i<reasons.length;i+=1){
            if(reasons[i].trim().length >0)
                cnt += 1
        }
        setCount(cnt)
        
        if(cnt === reasons.length)
            setSubmitVisible(true)
        else
            setSubmitVisible(false)
    },[ReasonList])

    const handleClick = (event) => {
        if (innerDivRef.current && !innerDivRef.current.contains(event.target)) {
          setRejectModalOpen(false); 
        }
      };
    const handleReasonChange = (event) => {
        setReasons(event.target.value);
      };
    const handleReasonListChange = (event) => {
        setReasonlist({
            ...ReasonList,
            [currentIdTab]: event.target.value,
          });
      };

    const changeStatus = (type) =>{     
        if(type === "CommonReason"){
            bulkSubmit("RejectCommon",Reasons,ReasonList)
            setReasonlist([])
            setReasons('')
            setShowWarning(0)
        }
        else if(type === "IndReason")
            bulkSubmit("RejectIndividual",Reasons,ReasonList)
            setReasonlist([])
            setReasons('')
            setShowWarning(0)
        setRejectModalOpen(false);
    }
    
      useEffect(() => {
        const updateButtonStates = () => {
            if (idListRef.current) {
                const { scrollLeft, scrollWidth, clientWidth } = idListRef.current;
                setCanScrollLeft(scrollLeft > 0);
                setCanScrollRight(scrollLeft < scrollWidth - clientWidth);
            }
        };
        updateButtonStates();
        const listElement = idListRef.current;
        if (listElement) {
            listElement.addEventListener('scroll', updateButtonStates);
        }
        return () => {
            if (listElement) {
                listElement.removeEventListener('scroll', updateButtonStates);
            }
        };
    }, [selectedOfferIds]);
    const handleScroll = (direction) => {
        if (idListRef.current) {
            const tabWidth = idListRef.current.querySelector('.idTab').offsetWidth;
            
            idListRef.current.scrollBy({
                left: direction === 'left' ? -(tabWidth) : (tabWidth),
                behavior: 'smooth'
            });

        }
    };

    
    return <div  onClick={handleClick} className="actionChangeModal" >
                <div ref={innerDivRef} className="innerDiv">
                    <div className="ReasonsTitle">Rejection Reason</div>
                    <div className="EntRea"> Please share Rejections Details Here</div>
                    <div className="tabs">
                        <div className={`tab ${currentTab==="Bulk" ? "active" : ''}`} onClick={()=>{setCurrentTab('Bulk')}}>Bulk</div>
                        <div className={`tab ${currentTab==="Single" ? "active" : ''}`} onClick={()=>{setCurrentTab('Single')}}>Single</div>
                    </div>
                    {currentTab==="Bulk"? 
                    <div>
                        <div>Reasons*</div>
                        <textarea className="textArea" value={Reasons} onChange={handleReasonChange} placeholder="Enter Common Reason for All Selected offers" />
                        <button className={`Submit ${Reasons.trim().length>=1 ? 'active' :''}`} onClick={()=>{if(Reasons.trim().length>=1 ){changeStatus("CommonReason")}}}> Submit </button>
                    </div> :
                    <div className="SingleReasons"> 
                        
                        <div className="TabsContainer">
                            {<div className={`navbtn ${selectedOfferIds.length >4 ? 'visible' : ''} ${canScrollLeft ? 'enabled' : 'disabled'}`} onClick={() => canScrollLeft && handleScroll('left')} ><Icons name="left-arrow" width="12" height="12" fill="rgba(0, 0, 0, 1)" /> </div>}
                        <div className="IdList"  ref={idListRef} style={{width:selectedOfferIds.length>4?'calc(100% - 60px)': '-webkit-fill-available;'}}>{selectedOfferIds.map((item)=>{return <div className={`idTab ${currentIdTab===item? 'active' : '' }`} onClick={()=>{setCurrentIdTab(item)}}><div>
                            {item}
                        </div>{ReasonList[item]?.trim().length>0 &&<Icons name ="checkbox" fill="#fff" width="16" height="16" />}</div>})}</div>
                        {<div className={`navbtn ${selectedOfferIds.length >4 ? 'visible' : ''} ${canScrollRight ? 'enabled' : 'disabled'}`} onClick={() => canScrollRight && handleScroll('right')}> <Icons name="right-arrow" width="12" height="12" fill="rgba(0, 0, 0, 1)" /> </div>}
                        </div>
                        
                        <div>Reasons*</div>
                        <textarea className="textArea" value={ReasonList[currentIdTab]} onChange={handleReasonListChange} placeholder="Enter Rejection Reason for this Offer" />
                        <div className="foot"> <div className="count">{count}/{reasons.length} offers reasons added</div><button className={`Submit ${submitVis ? 'active' : ''}`}  onClick={()=>{if(submitVis){changeStatus("IndReason")}}}> Submit </button> </div>
                    </div> }
                </div> 
            </div>
}
export default RejectModal