import { useState } from "react";
import Icons from "../Icons";
import "./style.scss"
import RejectModal from "../RejectModal";
import { toast } from "react-toastify";
const WarningInfo = ({totalOffers,bulkSubmit,setShowWarning,type,setSelectedOptions}) =>{

    const [action,setAction] = useState('')
    const [actHovered,setActionHovered] = useState(null)
    const [showActionsList,setShowActionsList]  = useState(false)
    const [rejctModalOpen,setRejectModalOpen] = useState(false)
    

    function checkAction(){
        if(action.trim()==="Reject"){
            setRejectModalOpen(true)
        }
        else if(action.trim()==="Approve"){
              
            bulkSubmit("Approve")
        }
        else if(action.trim() =="Submit for Approval"){
              
            bulkSubmit("OFFER_SUBMIT")
        }
        else if(action.trim() =="Pause"){
              
            bulkSubmit("OFFER_PAUSE")
        }
        else if(action.trim() =="Resume"){
              
            bulkSubmit("OFFER_RESUME")
        }
        else if(action.trim() === "Withdraw"){
              
            bulkSubmit("OFFER_WITHDRAW")
        }
    }
    function toggleShowActionsList(){
        setShowActionsList(!showActionsList)
    }

    const actionList = Object.values(JSON.parse(localStorage.getItem("wonderList"))).flat()[0].nextActions.split(',').filter(item=> item.trim()!=="Edit" && item.trim()!=="View" && item.trim()!=="Duplicate"  && item.trim()!=="View History")

    const message = type === -1 ?
    " Please select offers with the same status to change the status of multiple offers at once. You can use the search or filter to list offers with a common status. Thank you!"
    :<div style={{display:"flex",alignItems:"center"}}> 
        <div style={{ display: 'flex', alignItems:"center" ,fontSize:"14px", cursor:"pointer", marginRight:"54px"}} onClick={()=>{toggleShowActionsList()}}>
            <div style={{ marginLeft: '8px', color:"#E5E7EB" }}>Change Status</div>
            {action && <div style={{marginLeft:'4px'}}> : {action}</div>}
            <div style={{margin:"0 0 0 10px",paddingTop:"1px"}}>
            <Icons     
                    name= "arrow-down"
                    width= "10"
                    height= "10"
                    fill= "#E5E7EB"
            />
            </div>
        </div><button class={`okBtn ${action? '':'disabled' } `} onClick={checkAction}>Ok</button>
    </div>;
      
    return <div class="WarningInfo">
        {
            type === -1 || actionList?.length > 0 ? <div class='WarningMessage' style={{ backgroundColor: type === 1 ? "#111827" : "#DC2626" }}>
                {totalOffers} Offers Selected :{message}
            </div>
                : <></>
        }
    
                {showActionsList && <div className="actionList">
                    {actionList.map((item,index)=>{return<div class="action" style={{position:"relative"}}
                    onMouseEnter={()=>{setActionHovered(index)}}
                    onMouseLeave={()=>{setActionHovered()}}
                    onClick={()=>{setAction(item);setShowActionsList(false)}}
                    >{actHovered === index && <div className="checkMark">
                        <Icons name= "check"
                        width= "18"
                        height= "18"
                        fill= '#fff'/>
                        </div>}<div>{item}</div></div>})}
                </div>}

                {rejctModalOpen && <RejectModal setShowWarning = {setShowWarning} setRejectModalOpen={setRejectModalOpen} setSelectedOptions={setSelectedOptions} bulkSubmit={bulkSubmit} />}
            </div>
}
export default WarningInfo